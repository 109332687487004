<template>
    <div>
        <div class="bannerIndex">
            <div class="bannerstyle">
                <Banner></Banner>
            </div>
        </div>
        <div class="container">
            <BSCM/>
            <MehClub></MehClub>
            <tokenomics/>
            <!-- <buyBSCM/> -->
            <subText/>
        </div>
        
        <Alert v-model="moreModal">
            <div class="alert_text">
            {{ mintInfoText }}    
            </div>
        </Alert>

        <!-- <div class="firstModal" v-if="firstModal">
            <div class="close" @click="firstModal = false"></div>
            <div class="content">
                <p class="cpr" @click="openWin('https://discord.gg/GE6WXGFmCA/')"><img :src="isMobile ? require('@/assets/image/H5/firstDiscord.png') : require('@/assets/image/firstDiscord.png')" alt=""></p>
                <p class="cpr" @click="openWin('https://twitter.com/DiA0Club')"><img :src="isMobile ? require('@/assets/image/H5/firstTwitter.png') : require('@/assets/image/firstTwitter.png')" alt=""></p>
            </div>
        </div>
        <div class="new-popup" v-if="firstModal"></div> -->
        
        <!-- <Loading :loadingText="msg"></Loading> -->
    </div>
</template>
<script>
export default {
    components: {
        Banner: (resolve) => require(["@/components/banner"], resolve),
        BSCM: (resolve) => require(["./BSCM"], resolve),
        tokenomics: (resolve) => require(["./tokenomics"], resolve),
        buyBSCM: (resolve) => require(["./buyBSCM"], resolve),
        subText: (resolve) => require(["./subText"], resolve),
        MehClub: (resolve) => require(["./mehClub"], resolve),
        Loading: (resolve) => require(["@/components/LoadIng"], resolve),
        Alert:(resolve) => require(['@/components/Alert'], resolve)
    },
    data() {
        return {
            msg:'',
            mintInfoText:'',
            
            isMobile:false,
            moreModal:false,
            firstModal:false,
        };
    },
    computed: {

    },
    created(){
        this.msg = ''
    },
    mounted() {
        
    },
    methods: {
        // initModal(){
        //     let firstFlag = sessionStorage.getItem('first') || false
        //     if(!firstFlag){
        //         sessionStorage.setItem('first',true)
        //         this.firstModal = true
        //     }
        // },

        async toastTextFun(){
         this.msg = "Connecting wallet..."
            //  this.$Toast("COMING SOON")
            if (typeof window.ethereum === 'undefined') {
                this.$store.state.wallet_show = true
                this.$store.dispatch("InstallVal", true);
                console.log(this.$store.state.install)
                localStorage.setItem('install',this.$store.state.install)
                localStorage.removeItem("accounts");
                localStorage.removeItem("gasPrice");
                this.msg = ''
                return;
                // Toast('请确保您的浏览器已经安装了MetaMask插件')
                } else {
                console.log(window.ethereum)
                console.log(ethereum)
                this.$store.state.wallet_show = false
                localStorage.setItem('install',this.$store.state.install)
                this.$store.dispatch("InstallVal", true);
                try {
                    console.log("ethereum",ethereum)
                    ethereum = window.ethereum
                    await ethereum.enable()
                    location.reload();
                    this.msg = '';
                } catch (error) {
                    this.$store.state.wallet_show = true
                    this.$store.dispatch("InstallVal", false);
                    console.error('User denied account access')
                    this.msg = ''
                }
            }
        },
    }
};
</script>

<!-- Demo styles -->
<style lang="scss" scoped>
.alert_text{
    font-size: 18px;
    padding: 20px 42px;
    height: 310px;
    overflow: auto;
    color: #BFBFBF;
    line-height: 36px;
    letter-spacing: normal;
}
.payModal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
        width: 244px;
        margin: 60px 0;
    }
    p{
        font-size: 18px;
        color: #fff;
        &:first-child{
            font-size: 20px;
        }
        &.num{
            margin: 16px 0 5px;
        }
        &.view{
            a{
                text-decoration: underline;
                color: #F8C10A;
            }
        }
    }
}
.firstModal{
    width: 770px;
    height: 540px;
    // background: url(../../assets/image/firstBg.png) no-repeat left top/100%;
    position: fixed;
    left: 50%;top: 50%;
    transform: translate(-50%,-50%);
    z-index: 999;
    .close{
        width: 35px;
        height: 40px;
        // background: url(../../assets/image/firstClose.png) no-repeat left top/100%;
        position: absolute;
        top: 6px;right: 26px;
    }
    .content{
        margin: 278px 0 0 200px;
        p{
            width: 350px;
            height: 57px;
            margin-bottom: 37px;
        }
    }
}
@media screen and (max-width:768px) {
    .bannerIndex{
        margin-top: 43px;
    }
    
    .firstModal{
        width: 345px;
        height: 242px;
        // background: url(../../assets/image/H5/firstBg.png) no-repeat left top/100%;
        .close{
            width: 16px;
            height: 18px;
            top: 3px;right: 11px;
        }
        .content{
            margin: 125px 0 0 90px;
            p{
                width: 157px;
                height: 26px;
                margin-bottom: 16px;
            }
        }
    }
}
@media screen and (max-width:480px) {
    .payModal{
        img{
            width: 164px;
            margin: 40px 0;
        }
        p{
            font-size: 16px;
        }
    }
}
</style>